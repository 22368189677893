<template>
  <div id="app">
     <el-container style="">
      <el-aside width="22%" style="padding: 20px 0 0 0;background-color: #fff">
        <div>
          <el-row>
            <el-col :span="24" style="margin-bottom: 10px;color: #000">
              <el-page-header @back="goBack" content="任务信息"></el-page-header>
            </el-col>
          </el-row>
        </div>
        
        <div class="tree">
          <el-tree
            :data="device"
            :props="defaultProps"
            default-expand-all
            @node-click="changeDevice"
            ref="tree">
            <span slot-scope="{ node, device }" class="span__">
              <i class="el-icon-video-camera"></i>
              <!-- <i class="el-icon-video-camera-solid"></i> -->
              <span class="tree-node-span">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
        
        <div class="diviceOneMess">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="状态" name="first">
              <p>
                <b>设备号</b>
                <span style="">
                  {{deviceOne.did}}
                </span>
              </p>
              <p>
                <b>在线状态</b>
                <span style="">
                  {{deviceOne.online == 1 ? "在线":""}}
                  {{deviceOne.online == 0 ? "离线":""}}
                </span>
              </p>
             
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-aside>
      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <el-button @click="gohistory()" type="primary">历史记录</el-button>
        </el-header>
        <el-main>
          <div id="cmsv6player"></div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      @opened="afterEnter()"
      @closed="afterLeave()"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
        width="28%">
      <div>
         <div>
          <el-form ref="user" :model="user" label-width="55px">
            <el-col :span="20" :push="2">
              <el-form-item label="账号">
                <el-input v-model="user.account" placeholder="请输入账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" :push="2">
              <el-form-item label="密码">
                <el-input v-model="user.password" placeholder="请输入密码"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>
      <div slot="footer" style="margin:15px 32px 0 0;">
        <el-button type="primary" @click="login()">登 录</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import dataStorage from '@/../utils/dataStorage.js'
import BaseData from '@/assets/config/BaseData.js';

//设置视频插件的服务器ip
let serverIp = '39.97.231.64';
//登录服务器端口
let serverPort = '6605';

let swfobject;
export default {
  data() {
    return {
      dialogVisible:false,
      activeName: 'first',
      serverIp:serverIp,
      serverPort:serverPort,
      // filterText: '', //筛选
      device:[],     //树形控件 查询所有设备
      defaultProps: {
        children: 'son', 
        label: 'did'
      },
      deviceOne:{  // 点击树形的一个设备的信息 
                  // abbr
                  // did  设备号
                  // online 在线状态 1表示在线，0不在线
                  // vid   警员号
      }, 
      // 用户基本信息
      user: {
        account: "",
        password: "",
        jsession: "", // 	会话号
      },
      videoConfig:{
        winNum:1,    // 窗口数量
        stream:1,    // 主码流
        chnNum:0,    // //指定第几通道
        winIndex:0,   //  窗口下标
        title:"",
      },
      swfobject:swfobject,
      nodeCount:0,
      preNodeId:null,
      curNodeId:null,
      nodeTimer:null,

    };
  },
  created() {
     this.isLogin()
    // console.log(this.$route.query.deviceId);
     
   
  },
  mounted() {
		this.create()
	},
  watch: {
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    // } // 监听过滤
  },
  methods: {
    create() {
			let videoOption = {
				domId: 'cmsv6player', // id
				isVodMode: false, // 显示进度条,直播 false 回放 true
				width: 1000, // 窗口宽度
				height: 600,// 窗口高度
				lang: 'zh', // 语言类型
			}
			// 初始化播放器
			this.swfobject  = new Cmsv6Player(videoOption)
      // console.log(this.swfobject);
      //配置当前显示的窗口数目 窗口数量
			this.swfobject.setWindowNum(this.videoConfig.winNum);
			//设置视频插件的服务器ip和登录服务器端口
			this.swfobject.setServerInfo(this.serverIp, this.serverPort);
		},
    playVideo(){
      // 窗口下标 + title
      this.swfobject.setVideoInfo(this.videoConfig.winIndex,this.deviceOne.did + "-CH1")
			// 播放视频  (窗口下标 + 会议号（校验Jession） + 设备号 + 指定第几通道 + 主或子码流,0:主码流,1:子码流)
			this.swfobject.startVideo(
        this.videoConfig.winIndex,
        this.user.jsession,
        this.deviceOne.did, 
        this.videoConfig.chnNum, 
        this.videoConfig.stream, true);
      
    },
    // 获取设备在线状态
    showDevice(){
      axios.get('http://39.97.231.64:8088/StandardApiAction_getDeviceOlStatus.action',{
        params:{
            jsession:this.user.jsession, // 会议号
            // devIdno:"",  // 设备号
            // vehiIdno:"", // 警员号
            // status:"",   // 在线状态（0/1）
        }
      }).then(res=>{
        console.log( res.data.onlines);
        this.device = res.data.onlines
        this.device.forEach((value , index) => {
          value['playstatus'] = "0"
        })
        console.log(this.device);
        const aaa = this.$route.query.deviceId;
        this.device = this.device.filter(data => data.did.includes(aaa))
      })
    },
    login(){
       axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_login.action", {
          params: {
            account: this.user.account,
            password: this.user.password,
          },
        })
        .then((res) => {
          if (res.data.result == 0) {
            this.$message.success("登录成功")
            dataStorage.setBaseData("videoJsession", res.data.jsession)
            this.user.jsession = res.data.jsession
            this.dialogVisible = false
            this.showDevice()
          }else{
            this.$message.error("登录信息有误")
          }
        });
    },
    isLogin(){
      this.dialogVisible = true
    },
   afterEnter(){
    console.log("opened");
    // 在body上加入classname为allblur,即实现背景模糊
    document.getElementById('app').className = 'allblur'
    // 下面是原有的代码，不做修改
    this.$emit('opened')
   },
   afterLeave(){
      // 去掉body上添加的classname，即实现去除背景模糊
      document.getElementById('app').className = ''
      // 下面是原有的代码，不做修改
      this.$emit('closed')
   },

    // 筛选
    changeDevice(data){
        console.log(data);
          this.deviceOne = data
          this.nodeCount++
          if( this.preNodeId && this.nodeCount >= 2){
            this.curNodeId = data.vid 
            this.nodeCount = 0
            if(this.curNodeId == this.preNodeId){//第一次点击的节点和第二次点击的节点id相同
              // console.log('双击,执行代码写在这里');
              if (data.online == 1) {
                // 未播放情况
                if (this.deviceOne.playstatus == "0") {
                  this.playVideo()
                  this.deviceOne.playstatus = "1"
                  this.videoConfig.winIndex = this.videoConfig.winIndex + 1
                  if (this.videoConfig.winIndex > 9) {
                    this.videoConfig.winIndex = 0
                  }
                }else{
                  this.$message({
                  message: '设备正在播放中',
                  type: 'warning'
                });
                }
              }else{
                this.$message({
                  message: '设备离线',
                  type: 'warning'
                });
              }
              this.curNodeId = null
              this.preNodeId = null      
              return
            }
          }
          this.preNodeId = data.vid
          this.nodeTimer = setTimeout(() => { //300ms内没有第二次点击就把第一次点击的清空
            this.preNodeId  = null
            this.nodeCount = 0
          },300)   
     
    },

    handleClick(tab, event) {
        console.log(tab, event);
    },
     // 返回上一级
    goBack() {
      // this.$router.push("/WorkPlace");
      this.$router.go(-1)
    },
    gohistory() {
      this.$router.push({
        path:'./VideoRecordHistory',
        query: {
          deviceId:this.$route.query.deviceId,
          randomId: Math.floor((Math.random() * 10000) + 1)
        }
      }).catch(err => err)
    },
  },
};
</script>

<style lang="scss" scoped>

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.tree{
  width: inherit;
  height:100vh;
}
.diviceOneMess{
  width:inherit;
  height: 200px;
  position: fixed;
  bottom: 10px;
  border-radius: 10px;
  p {
    font-size: 14px;
    margin-bottom: 8px;
    b {
      color: #000000;
    }
    span {
      margin-left:20px;
    }
  }
}
/deep/.el-tabs__nav-scroll {
    overflow: hidden;
    background-color: aliceblue;
    text-align: center;
    padding-left: 20px;
}
/deep/.el-tabs__item.is-active {
    color: #409EFF;
    font-size: 16px;
    // background-color: #e1e1e1;
}
/deep/.el-form-item__label {
    font-size: 14px;
    font-weight: bold;
    color: #21722c;
}
/deep/.el-form {
    margin-top: 10px;
}
/deep/.el-form-item__content {
    line-height: 20px;
}
.el-page-header__content {
    // color: black !important;
}
// 模糊处理的css
.allblur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  zoom: 1;
}
// v-modal修改的弹出框是背景颜色的
.v-modal {
　　background-color: rgba(0,0,0,0.3) !important;
}
</style>
